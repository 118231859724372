const meta = {
  auth: true
};
export default [
  {
    path: "/product",
    name: "ProductIndex",
    component: () => import("@/views/product/index.vue"),
    meta: {
      ...meta,
      title: "首页"
    }
  },
  {
    path: "/product/order",
    name: "ProductOrderIndex",
    component: () => import("@/views/product/order/index.vue"),
    meta: {
      ...meta,
      title: "服务购买"
    }
  },
  {
    path: "/product/order/list",
    name: "ProductOrderList",
    component: () => import("@/views/product/order/list.vue"),
    meta: {
      ...meta,
      title: "订单管理"
    }
  },
  {
    path: "/product/order/detail",
    name: "ProductOrderDetail",
    component: () => import("@/views/product/order/detail.vue"),
    meta: {
      ...meta,
      title: "订单详情"
    }
  },
  {
    path: "/product/pay",
    name: "ProductPay",
    component: () => import("@/views/product/pay/index.vue"),
    meta: {
      ...meta,
      title: "支付"
    }
  },
  {
    path: "/product/pay/success",
    name: "ProductPaySuccess",
    component: () => import("@/views/product/pay/success.vue"),
    meta: {
      ...meta,
      title: "支付成功"
    }
  },
  {
    path: "/product/pay/finish",
    name: "ProductPayFinish",
    component: () => import("@/views/product/pay/finish.vue"),
    meta: {
      ...meta,
      title: "扣款成功"
    }
  },
  {
    path: "/product/otherPay",
    name: "ProductOtherPay",
    component: () => import("@/views/product/otherPay/index.vue"),
    meta: {
      title: "支付"
    }
  },
  {
    path: "/product/otherPay/middlePage",
    name: "ProductOtherPayMiddlePage",
    component: () => import("@/views/product/otherPay/middlePage.vue"),
    meta: {
      title: "支付"
    }
  },
  {
    path: "/product/otherPay/success",
    name: "ProductOtherPaySuccess",
    component: () => import("@/views/product/otherPay/success.vue"),
    meta: {
      title: "支付成功"
    }
  },
  {
    path: "/product/otherPay/info",
    name: "ProductOtherPayInfo",
    component: () => import("@/views/product/otherPay/info.vue"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/product/otherPay/finish",
    name: "ProductOtherPayFinish",
    component: () => import("@/views/product/otherPay/finish.vue"),
    meta: {
      title: "扣款成功"
    }
  },
  {
    path: "/product/contract/list",
    name: "ProductContractList",
    component: () => import("@/views/product/contract/list.vue"),
    meta: {
      ...meta,
      title: "合同管理"
    }
  },
  {
    path: "/product/contract/detail",
    name: "ProductContractDetail",
    component: () => import("@/views/product/contract/detail.vue"),
    meta: {
      ...meta,
      title: "合同管理"
    }
  },
  {
    path: "/product/contract/detailPdf",
    name: "ProductContractDetailPdf",
    component: () => import("@/views/product/contract/detailPdf.vue"),
    meta: {
      ...meta,
      title: "我的合同"
    }
  },
  {
    path: "/product/claim/list",
    name: "ProductClaimList",
    component: () => import("@/views/product/claim/list.vue"),
    meta: {
      ...meta,
      title: "履约申请"
    }
  },
  {
    path: "/product/claim/detail",
    name: "ProductClaimDetail",
    component: () => import("@/views/product/claim/detail.vue"),
    meta: {
      ...meta,
      title: "履约详情"
    }
  },
  {
    path: "/product/claim/applyList",
    name: "ProductApplyList",
    component: () => import("@/views/product/claim/applyList.vue"),
    meta: {
      ...meta,
      title: "服务查询"
    }
  },
  {
    path: "/product/claim/apply",
    name: "ProductClaimApply",
    component: () => import("@/views/product/claim/apply/index.vue"),
    meta: {
      ...meta,
      title: "服务申请"
    },
    children: [
      {
        path: "base",
        name: "BaseClaim",
        component: () => import("@/views/product/claim/apply/steps/base.vue"),
        meta: {
          ...meta,
          title: "填写信息"
        }
      },
      {
        path: "file",
        name: "FileClaim",
        component: () => import("@/views/product/claim/apply/steps/file.vue"),
        meta: {
          ...meta,
          title: "影像上传"
        }
      },
      {
        path: "success",
        name: "SuccessClaim",
        component: () => import("@/views/product/claim/apply/steps/success.vue"),
        meta: {
          ...meta,
          title: "影像上传"
        }
      }
    ]
  }
];
