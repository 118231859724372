import { request } from "@/api/service";
import store from "@/store";
/**
 * 查询项目下所有字典
 */
export function queryDictContentListAll(projectCode) {
  return request({
    url: `/afis-engine/dict/content/${projectCode}/listAll`,
    method: "get"
  });
}
/**
 * 获取字典
 */
export async function getDictInfo(typeCode) {
  const dict = JSON.parse(localStorage.getItem("afis-wx-dict_dictMap") || "{}");
  if (dict[typeCode]) {
    return Promise.resolve(dict[typeCode]);
  } else {
    return request({
      url: `/afis-engine/dict/content/product-factory/list/${typeCode}`,
      method: "get"
    }).then(res => {
      dict[typeCode] = res;
      store.dispatch("dict/set", dict);
      return Promise.resolve(res);
    });
  }
}
