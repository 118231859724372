<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "development") {
  // eslint-disable-next-line no-undef
  // let vConsole = new VConsole();
  // console.log(vConsole);
}
export default {};
</script>
<style lang="scss" scoped>
// #app {
//   background: rgb(239, 239, 249);
// }
</style>
