import { request } from "@/api/service";
/**
 * 登录
 */
export function wxlogin(wxCode) {
  return request({
    url: `/afis-auth/wx/mp/${process.env.VUE_APP_WX_APPID}/auth/identify`,
    method: "post",
    data: {
      code: wxCode
    }
  });
}

/**
 * 获取openId
 */
export function wxOauth(wxCode) {
  return request({
    url: `/afis-auth/wx/mp/${process.env.VUE_APP_WX_APPID}/auth/oauth`,
    method: "post",
    data: {
      code: wxCode
    }
  });
}
