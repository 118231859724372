export default [
  {
    path: "/",
    redirect: "/product",
    name: "Index",
    component: () => import("@/views/index.vue"),
    meta: {
      title: "首页"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录"
    }
  }
];
