export default [
  {
    path: "/customer/protocol",
    name: "CustomerProtocol",
    component: () => import("@/views/customer/protocol/index.vue"),
    meta: {
      title: "确认协议"
    }
  },
  {
    path: "/customer/signHelp",
    name: "CustomerSignHelp",
    component: () => import("@/views/customer/signHelp/index.vue"),
    meta: {
      title: "签署指引"
    }
  }
];
