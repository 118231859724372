import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/rem.js";

import "./vant";
import "@/assets/style/index.scss";
import dataV from "@jiaminghi/data-view";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import * as filters from "./filters";
// 全局注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.use(dataV);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
