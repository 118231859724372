import store from "@/store";
//  格式化方法
export function thousandSeparator(num) {
  return String(num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,"); // 千分位格式化
}
export function dictFormat(val, dictCode) {
  if (!(String(val) && dictCode)) {
    return null;
  }
  const dictOption = store.getters.dict[dictCode] || store.getters.dict[dictCode.toUpperCase()];
  const filterOption = dictOption
    ? dictOption.filter(dict => {
      return dict.value === val;
    })
    : [];
  return filterOption[0] && filterOption[0].label;
}
