import Vue from "vue";
import VueRouter from "vue-router";
import Product from "./modules/product";
import Common from "./modules/common";
import Customer from "./modules/customer";
import { isWxAuthorize, authorize } from "@/utils/weixin";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [...Product, ...Common, ...Customer];

const router = new VueRouter({
  routes
});
// mpRenewalTask
router.beforeEach(async (to, from, next) => {
  document.title = (to.meta && to.meta.title) || "鑫享服务";
  // 判断任务详情展示
  const autoFlag = to.meta && to.meta.auth;
  const autoLogin = autoFlag && (await isWxAuthorize());
  if (autoFlag && autoLogin === null) {
    next(false);
    return false;
  }
  if (autoFlag && !autoLogin) {
    authorize("#" + to.fullPath);
    next(false);
  } else {
    next();
  }
});
export default router;
